import { render, staticRenderFns } from "./AddAllergiesModal.vue?vue&type=template&id=4ca7f1d9&scoped=true&"
import script from "./AddAllergiesModal.vue?vue&type=script&lang=ts&"
export * from "./AddAllergiesModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ca7f1d9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BAlert } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
installComponents(component, {BAlert,BButton,BForm,BFormGroup})
