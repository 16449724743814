






























































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'

import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import HelpSearchCard from '@/components/HelpSearchCard.vue'
import AddAllergiesModal from '@/components/settings/AddAllergiesModal.vue'
import AddConditionsModal from '@/components/settings/AddConditionsModal.vue'
import EditChildSafePackagingModal from '@/components/settings/EditChildSafePackagingModal.vue'
import ModalSettingWrapper from '@/components/settings/ModalSettingWrapper.vue'
import User from '@/models/User'
import { formatDateMedium } from '@/i18n'

@Component({
  components: {
    Card,
    PageHeader,
    LinkArrowIcon,
    AddAllergiesModal,
    AddConditionsModal,
    HelpSearchCard,
    ModalSettingWrapper,
    EditChildSafePackagingModal,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('allergies', { allergies: 'allFormatted' }),
    ...mapGetters('conditions', { conditions: 'allFormatted' }),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class HealthProfileView extends Vue {
  isInProgress!: (key: string) => boolean
  me?: User
  allergies!: string
  conditions!: string

  get useChildSafePackaging(): boolean {
    return !!this.me?.preferences?.useChildSafePackaging
  }

  get loadingUser() {
    return this.isInProgress('user/loadMe')
  }

  get displayAllergies(): string {
    if (this.isInProgress('allergies/load')) return this.$t('Loading…') as string
    if (!this.allergies) return this.$t('No known allergies') as string

    return this.allergies
  }

  get displayConditions(): string {
    if (this.isInProgress('conditions/load')) return this.$t('Loading…') as string
    if (!this.conditions) return this.$t('No known conditions') as string

    return this.conditions
  }

  get healthInfo() {
    const me = this.me
    if (!me) return []

    return [
      {
        label: this.$t('Legal name'),
        value: `${me.firstName} ${me.lastName}`,
      },
      {
        label: this.$t('Date of birth'),
        value: formatDateMedium(me.birthdate),
      },
      {
        label: this.$t('Sex'),
        value: me.female ? this.$t('Female') : this.$t('Male'),
      },
    ]
  }

  get breadcrumbs() {
    return [
      {
        text: this.$t('pages.settings.title'),
        to: { name: 'settings' },
      },
      {
        text: this.$t('pages.settings.health.title'),
        to: { name: 'health-profile' },
      },
    ]
  }

  async created() {
    await this.$store.dispatch('user/ensureMe')
    this.$store.dispatch('allergies/load')
    this.$store.dispatch('conditions/load')
  }
}
