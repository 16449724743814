
































import { Component, Vue, Prop } from 'vue-property-decorator'
import Autocomplete from '@/components/inputs/autocomplete/Autocomplete.vue'

@Component({
  components: {
    Autocomplete,
  },
})
export default class TokenList extends Vue {
  @Prop(Array)
  value!: any[]

  @Prop({ type: Function, default: (a: string) => a })
  format!: (value: any) => string

  get tokens() {
    return this.value.slice()
  }

  set tokens(value: any[]) {
    this.$emit('input', value)
  }

  addCustom(text: string) {
    if (!text) return
    this.$emit('addCustom', text)
    this.tokens = [...this.tokens, text]
  }

  addToken(token: any) {
    if (!token) return
    this.$emit('add', token)
    this.tokens = [...this.tokens, this.format(token)]
  }

  removeToken(index: number) {
    const newValue = this.tokens.slice()
    const [token] = newValue.splice(index, 1)
    this.$emit('remove', { index, token })
    this.tokens = newValue
  }

  emitSearch(query: string) {
    this.$emit('search', query)
  }

  removeLast() {
    if (this.tokens.length) {
      this.removeToken(this.tokens.length - 1)
    }
  }
}
