



















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { Condition } from '@/models/Conditions'

import Modal from '@/components/Modal.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import TokenField from '@/components/TokenField.vue'

interface ConditionToken {
  id: string
  displayName: string
  icd10?: string
}

@Component({
  components: {
    Modal,
    LoadingSpinner,
    TokenField,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
    ...mapState('conditions', ['conditions', 'searchResults', 'currentQuery']),
  },
})
export default class AddConditionsModal extends Vue {
  conditions!: Condition[]
  searchResults!: Condition[]
  currentQuery!: string
  isInProgress!: (key: string) => boolean
  getError!: (key: string) => Error

  @Prop({ type: String, required: true })
  modalId!: string

  // Data
  newConditions: ConditionToken[] = []

  get filteredConditions(): Condition[] {
    return this.searchResults
      .filter(c => !this.userHasCondition(c))
      .filter(c => !this.isSelected(c))
  }

  searchConditions(query: string) {
    this.$store.dispatch('conditions/search', query)
  }

  get searchErrorMessage() {
    const error = this.getError('conditions/search')
    return error && this.$t('Unable to search conditions, try again later')
  }

  get errorMessage() {
    const error = this.getError('conditions/addCondition')
    return error && this.$t('Unable to update conditions: {error}', { error })
  }

  async submit(ok: () => void) {
    // validate
    if (!this.newConditions.length) return
    // send request
    const conditionIds: string[] = []

    this.newConditions.forEach(condition => {
      conditionIds.push(condition.id)
    })

    if (conditionIds.length) {
      await this.$store.dispatch('conditions/addConditions', { conditionIds })
    }

    // handle failures
    const error = this.getError('conditions/addCondition')
    if (!error) {
      ok()
    }
  }

  userHasCondition(condition: Condition) {
    return this.conditions.some(c => c.id === condition.id)
  }

  isSelected({ id }: { id: string }): boolean {
    return this.newConditions.some(c => c.id === id)
  }

  selectCondition(condition: Condition) {
    if (this.userHasCondition(condition) || this.isSelected(condition)) return
    this.newConditions.push(condition)
    this.$store.commit('conditions/resetSearch')
  }

  addCustomCondition(name: string) {
    if (this.isSelected({ id: name })) return
    this.newConditions.push({ id: name, displayName: name })
  }

  removeCondition({ index }: { index: number }) {
    this.newConditions.splice(index, 1)
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.newConditions = []
    this.$store.commit('conditions/resetSearch')
    this.$store.commit('asyncStatus/reset', { key: 'conditions/addCondition' })
  }
}
