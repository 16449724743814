import { render, staticRenderFns } from "./HealthProfileView.vue?vue&type=template&id=7b182919&scoped=true&"
import script from "./HealthProfileView.vue?vue&type=script&lang=ts&"
export * from "./HealthProfileView.vue?vue&type=script&lang=ts&"
import style0 from "./HealthProfileView.vue?vue&type=style&index=0&id=7b182919&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b182919",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BLink } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import IconHealth from '@/assets/icons/icon-health.svg'
installComponents(component, {BLink,BListGroup,BListGroupItem,IconHealth})
