



















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { Allergy } from '@/models/Allergies'

import Modal from '@/components/Modal.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import TokenField from '@/components/TokenField.vue'
import featureFlags from '@/util/featureFlags'

interface AllergyToken {
  id: string
  displayName: string
  code?: string
}

@Component({
  components: {
    Modal,
    LoadingSpinner,
    TokenField,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
    ...mapState('allergies', ['allergies', 'commonAllergies', 'currentQuery', 'searchResults']),
  },
})
export default class AddAllergiesModal extends Vue {
  allergies!: Allergy[]
  commonAllergies!: Allergy[]
  searchResults!: Allergy[]
  currentQuery!: string
  isInProgress!: (key: string) => boolean
  getError!: (key: string) => Error

  @Prop({ type: String, required: true })
  modalId!: string

  // Data
  newAllergies: AllergyToken[] = []
  allergySearchEnabled = false

  async created() {
    this.allergySearchEnabled = await featureFlags.enabled('allergy-search')
  }

  get isSearching(): boolean {
    return this.allergySearchEnabled
      ? !!this.currentQuery
      : this.isInProgress('allergies/loadCommon')
  }

  get searchOptions() {
    if (this.allergySearchEnabled) {
      return { threshold: 1, shouldSort: false }
    }
    return null
  }

  get filteredAllergies(): Allergy[] {
    const collection = this.allergySearchEnabled ? this.searchResults : this.commonAllergies
    return collection.filter(a => !this.userHasAllergy(a)).filter(a => !this.isSelected(a))
  }

  get errorMessage() {
    const error = this.getError('allergies/addAllergy')
    return error && this.$t('Unable to update allergies: {error}', { error })
  }

  searchAllergies(query: string) {
    if (this.allergySearchEnabled) {
      this.$store.dispatch('allergies/search', query)
    }
  }

  async submit(ok: () => void) {
    // validate
    if (!this.newAllergies.length) return
    // send request
    const allergyIds: string[] = []
    const otherAllergies: string[] = []

    this.newAllergies.forEach(allergy => {
      if (allergy.code) {
        // If the id is set, this is a registered allergy
        allergyIds.push(allergy.id)
      } else {
        otherAllergies.push(allergy.displayName)
      }
    })

    if (otherAllergies.length || allergyIds.length) {
      await this.$store.dispatch('allergies/addAllergies', { allergyIds, otherAllergies })
    }

    // handle failures
    const error = this.getError('allergies/addAllergy')
    if (!error) {
      ok()
    }
  }

  onShow() {
    if (!this.commonAllergies.length) {
      this.$store.dispatch('allergies/loadCommon')
    }
  }

  userHasAllergy(allergy: Allergy) {
    return this.allergies.some(c => c.id === allergy.id)
  }

  isSelected({ id }: { id: string }): boolean {
    return this.newAllergies.some(a => a.id === id)
  }

  selectAllergy(allergy: Allergy) {
    if (this.userHasAllergy(allergy) || this.isSelected(allergy)) return
    this.newAllergies.push(allergy)
    this.$store.commit('allergies/resetSearch')
  }

  addCustomAllergy(name: string) {
    if (this.isSelected({ id: name })) return
    this.newAllergies.push({ id: name, displayName: name })
  }

  removeAllergy({ index }: { index: number }) {
    this.newAllergies.splice(index, 1)
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.newAllergies = []
    this.$store.commit('asyncStatus/reset', { key: 'allergies/addAllergy' })
  }
}
